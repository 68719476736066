<template>
  <vx-card title="Payment Proposal">
    <vs-tabs>
      <vs-tab label="Valid Invoice">
        <open></open>
      </vs-tab>
      <vs-tab label="Pending">
        <div class="tab-text">
          <pending></pending>
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="tab-text">
          <approved></approved>
        </div>
      </vs-tab>
      <vs-tab label="Paid">
        <div class="tab-text">
          <closed
            po_type="all"
          ></closed>
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="tab-text">
          <rejected></rejected>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Open from "./open";
import Pending from "./pending";
import Approved from "./approved";
import WaitingPayment from "./waiting_payment";
import Closed from "./closed";
import Payment from "./payment";
import Rejected from "./rejected/rejected.vue";
export default {
  components: {
    Open,
    Pending,
    Approved,
    Closed,
    WaitingPayment,
    Payment,
    Rejected,
  },
};
</script>